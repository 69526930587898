import React, { useEffect, useContext, useState, useMemo } from "react";
import './Accueil.scss'
import ExpoEnCours from 'components/Exposition/ExpoEnCours';
import RdvTile from 'components/Rdv/RdvTile';
import BlogTile from 'components/Blog/BlogTile';
import AcInstanceTile from 'components/Ac/AcInstanceTile';
import ResidenceTile from 'components/Residence/ResidenceTile';
import ExpositionTile from 'components/Exposition/ExpositionTile';
import EditionBloc from 'components/Edition/EditionBloc';
import Grid from '@material-ui/core/Grid';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";
import { SettingsContext } from 'Providers/SettingsProvider';
import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import Mod from 'components/Mod';

SwiperCore.use([Navigation, Autoplay, A11y]);
const parseDate=(rdv)=>DateTime.fromISO(rdv.date);

function Accueil() {
  const { updateLoadState, fetchedList, settings } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.accueil,{
    variables: {
      maintenant:DateTime.now().startOf('day').toISODate(),
      hier:DateTime.now().minus({ days: 1 }).startOf('day').toISODate(),
    },
  });
  const [ activeSlide,setActiveSlide ] = useState(1);
  const uuid='accueil';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let une= data ? data.une : {blocs:[]};
  const rdvs=useMemo(()=>{
    const tab=[];
    (data ? data.rdvs : []).forEach((rdv, i) => {
        const {date, time_from, time_to, repetitions, ...otherProps} = rdv;
        let currentRdv={...otherProps};
        if ( DateTime.fromISO(date)>=DateTime.now().startOf('day')) tab.push({...currentRdv, date, time_from, time_to});
        repetitions.forEach((r, i) => {
          if ( DateTime.fromISO(r.date)>=DateTime.now().startOf('day')) tab.push({...currentRdv, date:r.date, time_from:r.time_from, time_to:r.time_to});
        });
    });
    tab.sort((a,b)=>{
      const dateA=parseDate(a)+' '+a.time_from;
      const dateB=parseDate(b)+' '+b.time_from;
      if (dateA < dateB ) return -1;
      if (dateA > dateB ) return 1;
      return 0;
    });
    return tab;
  },[data]);
  let actus= data ? data.actus : [];
  let expos= data ? data.expositions.filter((o)=>o.une || DateTime.fromISO(o.date_to)>=DateTime.now().startOf('day')) : [];
  let actuIdx=0;
  let rdvIdx=0;
  useEffect(()=>{
    if (settings && settings.websitename) document.title=settings.websitename;
  },[settings]);
  return (<div className="accueil">
    {expos.length>0 && <Swiper
    speed={800}
    loop
    autoplay={{
      delay: 6000,
    }}
    enabled={expos.length>1}
    navigation={expos.length>1}
    onActiveIndexChange={(swiper)=>setActiveSlide(swiper.activeIndex)}
    >
      {expos.map((expo,i)=>{
        let label;
        const now=DateTime.now().startOf('day');
        if (
          DateTime.fromISO(expo.date_to)>=now
          &&
          DateTime.fromISO(expo.date_from)<=now
        ) label='expo-en-cours';
        if (DateTime.fromISO(expo.date_to)<now) label='exposition-passee';
        if (DateTime.fromISO(expo.date_from)>now) label='exposition-a-venir';
        return <SwiperSlide key={expo.id}>
          <ExpoEnCours expo={expo} home={true} reset={activeSlide} label={label}/>
        </SwiperSlide>
      })}
    </Swiper>}
    <Grid container spacing={2}>
      {une && une.blocs.map((bloc)=>{
        if (bloc.type==='rdv') {
          let rdv;
          if (bloc.auto==='auto') {
            if (bloc.rdvactu==='rdv') {
              rdv=rdvs[rdvIdx];
              rdvIdx++;
            }
            if (bloc.rdvactu==='actualite') {
              rdv=actus[actuIdx];
              actuIdx++;
            }
          } else {
            rdv=bloc.rdv;
          }
          if (bloc.size==='billet' && rdv) {
            return <Grid key={bloc.id} item xs={12} sm={6} md={4} >
              {rdv ? (rdv.agenda==='blog' ? <BlogTile blog={rdv}/> : <RdvTile rdv={rdv}/>) : null}
            </Grid>
          }
          if (bloc.size==='large' && rdv) {
            return <Grid key={bloc.id} item xs={12}>
              {rdv ? (rdv.agenda==='blog' ? <BlogTile blog={rdv}/> : <RdvTile rdv={rdv}/>) : null}
            </Grid>
          }
        }
        if (bloc.type==='ac_instance' && bloc.ac_instance) {
          if (bloc.size==='billet') {
            return <Grid key={bloc.id} item xs={12} sm={6} md={4} >
              <AcInstanceTile acInstance={bloc.ac_instance}/>
            </Grid>
          }
          if (bloc.size==='large') {
            return <Grid key={bloc.id} item xs={12}>
              EAC
            </Grid>
          }
        }
        if (bloc.type==='exposition' && bloc.exposition) {
          return <Grid key={bloc.id} item xs={12}>
            <ExpositionTile expo={bloc.exposition}/>
          </Grid>
        }
        if (bloc.type==='residence' && bloc.residence) {
          return <Grid key={bloc.id} item xs={12}>
            <ResidenceTile residence={bloc.residence}/>
          </Grid>
        }
        if (bloc.type==='edition' && bloc.edition) {
          return <Grid key={bloc.id} item xs={12}>
            <EditionBloc edition={bloc.edition}/>
          </Grid>
        }
        return null;
      })}
    </Grid>
    <Mod url={'une'} refresh={refetch}/>
  </div>);
}
export default Accueil;
