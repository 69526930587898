import React, { useEffect, useContext } from "react";
import './Blog.scss'
import BlogTile from 'components/Blog/BlogTile';
import Grid from '@material-ui/core/Grid';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";
import 'components/CartoucheInfos/CartoucheInfos.scss';
import Pagination from 'components/Pagination';
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import Mod from 'components/Mod';

const parseDate=(blog)=>DateTime.fromISO(blog.date);

function Blogs() {
  const { updateLoadState, fetchedList, appState, t } = useContext(SettingsContext);
  const { refetch, networkStatus, data } = useQuery(Queries.blogs);
  const uuid='blogs';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  let blogs= data ? data.blogs : [];
  let computedBlog=[...blogs];
  computedBlog.sort((a,b)=>{
    const dateA=parseDate(a)+' '+a.time_from;
    const dateB=parseDate(b)+' '+b.time_from;
    if (dateA < dateB )
       return 1;
    if (dateA > dateB )
       return -1;
    return 0;
  });
  const blogList=computedBlog;
  const nb=blogList ? blogList.length : 0;
  const page = (appState.paginations[uuid] && appState.paginations[uuid].page) || 1;
  useEffect(()=>{
    document.title=t('actualites')
  },[t]);
  return (<div className="blogs">
  <Grid container spacing={2}>
    <Grid item xs={12}>
      {nb>0 && <Pagination paginationId={uuid} page={page} nb={nb} typeSingulier='billet' typePluriel='billets' perPage={Conf.perPage}/>}
      <Grid container spacing={2}>
          {blogList.map((blog,i)=>{
            const from=(page-1)*Conf.perPage+1;
            const to=Math.min(nb,page*Conf.perPage);
            if (i+1>=from && i+1<=to) {
              return <Grid key={i} item xs={12} sm={4}>
                  <BlogTile blog={blog}/>
              </Grid>;
            }
            return null;
          })}
      </Grid>
    </Grid>
  </Grid>
    <Mod url={'rdvs'} refresh={refetch} dy={35}/>
  </div>);
}
export default Blogs;
